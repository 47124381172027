body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.image {
  width: 100%;
}